import React, { Component } from "react";
import Header from "./Header";
import "./SubscriptionPage.css";
import { BsArrowLeft } from "react-icons/bs";
import mockup from "../../assets/images/mockup.png";
import { AiFillCheckCircle } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Helmet} from "react-helmet";
import AppService from "../../commons/AppService";
import { FaUserAlt, FaEnvelope, FaLock, FaRegEye, FaRegEyeSlash, FaCreditCard, FaRegCreditCard, FaPercent, FaCheckCircle } from "react-icons/fa";
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as StripeSvg } from "../../assets/images/stripe-logo.svg";
import { Subject, map, mergeMap, debounceTime } from "rxjs";
import IconButton from '@mui/material/IconButton';

export default class SubscriptionPage extends Component {

  service = AppService.getInstance();
  promoChangeHandler = new Subject();

  constructor(props) {
    super(props);

    this.state = {
      token: "",
      planDetails: [],
      selectedPriceIdx: null,
      isLoading: false,


      validEmail: false,
      validName: false,
      validPassword: false,
      emailErrorMsg: "",
      passwordErrorMsg: "",
      nameErrorMsg: "",
      email: "",
      name: "",
      password: "",
      emailError: false,
      passwordError: false,
      nameError: false,
      errors: {},
      errorMsgText: "",
      errorMsg: false,
      isPasswordShow: false,
      focus: false,
      passwordFocus: false,
      hidePassword: true,
      nameFocus: false,

      captcha:'',
      
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvc: "",
      couponId:null,
      promoCode:null
    };

  }
  componentDidMount() {
    this.setState({
      token: this.service.getCurrentAccessToken(),
    });
    this.getAllPlans();

    this.promoChangeHandler.asObservable()
      .pipe(
        // The result is an HTML onChange event
        map(event =>  (event && event.target) ? event.target.value : ""),
        debounceTime(1000),
      )
      .subscribe(val => this.promoCodeSearch(val) );

  }
  getAllPlans = () => {
    this.setState({
      isLoading: true,
    });
    
    this.service.getPublicPlans()
      .then((result) => {
        //console.log("ENTER getPublicPlans success",result)
        // We need to iterate through the list of PlanStus (which are the stripe list of Prices) and check for a meta field name hasTrial to add trial plans attached to the same Price.
        // To make a Price/Plan as trial go to Stripe dashboard select the Product/Price and add a meta value with hasTrial=true
        var trailPlans = []
        result.forEach(item => {
          if (item.meta && item.meta["hasTrial"]) trailPlans.push({id:item.id,interval:"month",price:0,usageType:"trial"})
        })
        this.setState({
          //planDetails: result.concat(trailPlans),
          planDetails: result,
          isLoading: false,
        });
      })
      .catch((error) => {
        //console.log("ENTER getPublicPlans error",error)
        toast.error("Oops Something Went Wrong" + ((error.message) ? error.message : error) );

        this.setState({
          isLoading: false,
        });
      });
  };
  goToSWellMapOverview() {
    if (window.location.pathname == "/subscription") {
      this.props.history.push("/");
    } else {
      const locaion_name = this.props.match.params.location_name;
      this.props.history.push(`/swell_map/${locaion_name}`);
      window.location.reload();
    }
  }
  goToStripePage = (priceId, usageType) => {
    //console.log("ENTER goToStripePage priceId:" + priceId);
    // If user is logged in then send to Stripe page, otherwise send them to register/login to have them register before subscribing.
    if (this.service.getCurrentAccessToken()) {
      this.props.history.push(`/stripe/${priceId}/${usageType}`);
    } else {
      toast.info("Please register or sign in with your existing account before subscribing.");
      this.props.history.push('/register');
    }
  };

  handleKeyPress(value, event) {
    if (event.key === "Enter") {
      this.refs[value].focus();
    }
  }

  handleValidation() {
    const { email, password, name } = this.state;
    let errors = {};
    let formIsValid = true;
    let emailRegExp = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/;
    let passwordRegEx = "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$";

    //name
    if (!name) {
      formIsValid = false;
      errors["name"] = "Name is required";
      this.setState({
        nameError: true,
      });
    }

    //email
    if (this.state.email == "") {
      formIsValid = false;
      errors["email"] = "Email is required";
      this.setState({
        emailError: true,
      });
    } else if (typeof email !== "undefined") {
      if (!email.match(emailRegExp)) {
        formIsValid = false;
        errors["email"] = "Invalid email address";
        this.setState({
          emailError: true,
        });
      }
    }

    //password
    if (!password) {
      formIsValid = false;
      errors["password"] = "Password is required";
      this.setState({
        passwordError: true,
      });
    } 
    else if (typeof password !== "undefined") {
      if (password.length < 8 ) {
        formIsValid = false;
        errors["password"] =
          "Password must be atleast 8 characters long";
        this.setState({
          passwordError: true,
        });
      }
    }

    if (!this.state.cardNumber) {
      formIsValid = false;
      errors["cardNumber"] = "Credit card number required";
    }
    if (!this.state.cardMonth) {
      formIsValid = false;
      errors["cardMonth"] = "Credit card expire month required";
    }
    if (!this.state.cardYear) {
      formIsValid = false;
      errors["cardYear"] = "Credit card expire year required";
    }
    if (!this.state.cardCvc) {
      formIsValid = false;
      errors["cardCvc"] = "Credit card CVC required";
    }

    // Selected plan
    if (this.state.selectedPriceIdx == null) {
      formIsValid = false;
      errors["selectedPriceIdx"] = "Please select a Plan";
    }

    // Promo code
    //console.log("ENTER calidate promo code ", this.state.promoCode);
    //console.log("ENTER calidate couponId ", this.state.couponId);
    if ( this.state.promoCode && !this.state.couponId) {
      formIsValid = false;
      errors["couponId"] = "The promo code entered does not seem valid or is expired";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  resisterUser = () => {

    if (this.handleValidation()) {
      this.setState({
        isLoading: true,
      });

      var selectedPlan = this.state.planDetails[this.state.selectedPriceIdx];

      // First register user in vasat
      this.service.registerNewUser(this.state.name, this.state.email, this.state.password, this.state.captcha)
        .pipe(
          // If registered successfully create the payment method in stripe
          mergeMap(res => this.service.createPaymentMethod(this.state.cardNumber,this.state.cardMonth,this.state.cardYear,this.state.cardCvc,this.state.captcha) ),
          // If credit card added then proceed to subscribe to plan
          mergeMap(paymentId => this.service.createSubscription(selectedPlan.id, paymentId, selectedPlan.usageType == "trial", "USD", this.state.couponId) )
        ).subscribe(
          succ => {
            this.setState({
              isLoading: false,
            });
            toast.success("Registration Successful!", {autoClose: 1000});
            this.props.history.push("/");
          }, error => {
            this.setState({
              isLoading: false,
            });
            toast.error("Oops Something Went Wrong" + ((error.message) ? error.message : error) );
          }
        )
    }
  };

  promoCodeSearch(code) {
    if (code) {
      this.service.getPromotionCodes(code).subscribe(res => {
        this.setState({
          couponId: (res && res.length > 0) ? res[0].couponId : null,
          promoCode:code,
          errors: (res && res.length > 0) ? Object.assign(this.state.errors, {couponId:null}) : this.state.errors
        });
      });
    } else {
      this.setState({promoCode:code,couponId:null,errors:Object.assign(this.state.errors, {couponId:null})});
    }
  };
  

  numericKeyPress(event) {
    //console.log("ENTER event",event)
    if (event.charCode < 48 || event.charCode > 57) event.preventDefault();
  }

  render() {
    const { errors, validEmail, validPassword, validName } = this.state;
    const { isPasswordShow } = this.state;

    return (
      <div>
        <Helmet>
          <title>Subscription | SURFERforecast Maps</title>
          <meta name="description" content=" Subscription Pricing for 7-Day SURFERforecast Map Premium Access"></meta>
        
        </Helmet>
       <Header /> 
        <div>
          <div className="subscription-container">
            <div className="sub-main-container">
              <div className="flex-row">
                
                <div className="flex-column">
                  <div className="flex-row mt-3 pointer" onClick={() => this.props.history.push("/")}>
                    <BsArrowLeft
                      className="primary-color"
                      style={{
                        position: "relative",
                        fontSize: "30px",
                        top: "-6px",
                      }}
                    />
                    <p className="f-bold">
                      Back to{" "}
                      {window.location.pathname == "/subscription"
                        ? "Home"
                        : "Forecast"}
                    </p>
                  </div>
                  <span className="f-bold f-l mb-1">Subscribe Now!</span>
                  <p className="subs-left-subtext mb-1">
                    <b>
                      {" "}
                     10 day surf wave height & period maps for Southern California, Northern California and Hawaii
                    </b>
                  </p>
                  <div className="subs-card">
                    <div className="flex-column p-2">

                      <span className="f-bold f-l mb-1">1. Choose Your Membership</span>
                      <div className="cards">
                        {this.state.planDetails.map((data, idx) => (
                          <div 
                            className="cards-wrap pointer" 
                            id={`cw${idx}`} 
                            key={`cw${idx}`}
                            onClick={() => this.setState({selectedPriceIdx:idx,errors:Object.assign(errors, {selectedPriceIdx:null}) })}
                            >
                            <div className={`price-card pt-2 ${ (this.state.selectedPriceIdx == idx) ? 'selected-card' : 'normal-card'}`}>
                              <span className="f-l f-bold mb-1">{(data.usageType == "recurring") ? "Premium" : "30 Day Free Trial"}</span>
                              <p
                                className="subs-plan-subtext"
                                style={{ textTransform: "capitalize" }}
                              >
                                {data.interval}
                                {data.interval === 'year' || data.interval === 'month' ? <br /> : ''}
                                {data.interval === 'year' ? '(12 Months)' : ' '}
                                {data.interval === 'month' ? '(30 Days)' : ' '}
                              </p>
                              <br></br>
                              <p className="subs-plan-title">
                                $ {data.price}
                                <span
                                  style={{
                                    fontSize: "0.9em",
                                    fontFamily: "Source-REg",
                                    textAlign: "center",
                                    color: "",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {" "}
                                  / {data.interval}
                                </span>
                              </p>

                              <div className="mb-1">
                                <button className="subscribe-btn">Select</button>
                              </div>
                            </div>
                          </div>
                        ))}
                        { (this.state.errors["selectedPriceIdx"]) ? (
                          <span style={{color:'red'}}>{this.state.errors["selectedPriceIdx"]}</span>
                        ) : null }
                      </div>
                      <div className="details-section">
                        <div className="flex-column flex-50">
                          <span className="f-bold f-l mb-1">2. Enter Your Details</span>
                          <TextField 
                            label="Name" 
                            placeholder="Name"
                            className="register-input"
                            type="text" 
                            InputProps={{
                              startAdornment: <InputAdornment position="start"><FaUserAlt /></InputAdornment>,
                            }}
                            onBlur={this.validateName}
                            onChange={(e) =>
                              this.setState({
                                name: e.target.value,
                                nameError: false,
                                errorMsg: false,
                                validName: false,
                                nameErrorMsg: "",
                              })
                            }
                            value={this.state.name}
                            onKeyPress={(event) => this.handleKeyPress("email", event)}
                            helperText={ (this.state.validName || this.state.nameError) ? this.state.nameErrorMsg || errors["name"] : ""}
                            error={ (this.state.validName || this.state.nameError) ? true : false }
                          />

                          <TextField 
                            label="Email Address" 
                            placeholder="Email Address"
                            className="register-input"
                            type="email" 
                            InputProps={{
                              startAdornment: <InputAdornment position="start"><FaEnvelope /></InputAdornment>,
                            }}
                            onBlur={this.validateEmail}
                            onChange={(e) =>
                              this.setState({
                                email: e.target.value,
                                emailError: false,
                                errorMsg: false,
                                validEmail: false,
                                emailErrorMsg: "",
                              })
                            }
                            value={this.state.email}
                            onKeyPress={(event) => this.handleKeyPress("password", event)}
                            helperText={ (this.state.validEmail || this.state.emailError) ? this.state.emailErrorMsg || errors["email"] : ""}
                            error={ (this.state.validEmail || this.state.emailError) ? true : false }
                          />

                          <TextField 
                            label="Password" 
                            placeholder="Password"
                            className="register-input"
                            type={ this.state.hidePassword ? "password" : "text" } 
                            InputProps={{
                              startAdornment: <InputAdornment position="start"><FaLock /></InputAdornment>,
                              endAdornment: <InputAdornment position="start"><IconButton onClick={ () => this.setState({hidePassword:!this.state.hidePassword}) }> {this.state.hidePassword ? (<FaRegEye />) : (<FaRegEyeSlash/>)} </IconButton></InputAdornment>
                            }}
                            onChange={(e) =>
                              this.setState({
                                password: e.target.value,
                                passwordError: false,
                                validPassword: false,
                                passwordErrorMsg: "",
                              })
                            }
                            onBlur={this.validatePassword}
                            value={this.state.password}
                            helperText={ (this.state.validPassword || this.state.passwordError) ? errors["password"] || this.state.passwordErrorMsg : ""}
                            error={ (this.state.validPassword || this.state.passwordError) ? true : false }
                          />

                          <img src={this.service.vasat.config.host + '/captcha.png'} class="captcha" />
                          <TextField 
                            label="Enter the text you see above" 
                            placeholder=""
                            className="register-input"
                            type={   "text" } 
                            InputProps={{
                              startAdornment: <InputAdornment position="start"><FaLock /></InputAdornment>,
                              endAdornment: <InputAdornment position="start"><IconButton onClick={ () => this.setState({hidePassword:!this.state.hidePassword}) }> {this.state.hidePassword ? (<FaRegEye />) : (<FaRegEyeSlash/>)} </IconButton></InputAdornment>
                            }}
                            onChange={(e) =>
                              this.setState({
                                captcha: e.target.value
                              })
                            }
                            value={this.state.captcha}
                          />

                        </div>
                        <div style={{width:"18px"}}></div>
                        <div className="flex-column flex-50">
                          <span className="f-bold f-l mb-1">3. Enter Your Payment Method</span>
                          <div className="flex-column">
                            <div>
                              <TextField 
                                label="Card Number" 
                                placeholder="xxxx xxxx xxxx xxxx"
                                type="text" 
                                className="register-input"
                                inputProps={{maxLength:16, inputMode:'numeric'}}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start"><FaCreditCard /></InputAdornment>
                                }}
                                value={this.state.cardNumber} 
                                onChange={(e) => this.setState({cardNumber:e.target.value,errors:Object.assign(errors, {cardNumber:null}) }) }
                                onKeyPress={(e) => this.numericKeyPress(e)}
                                helperText={ (!this.state.cardNumber) ? errors["cardNumber"] : ""}
                                error={ (errors["cardNumber"]) ? true : false } />
                                
                            </div>
                            <div style={{marginTop: "12px",display: "flex", flexDirection: "row", background:"transparent"}}>
                              <TextField 
                                label="Expiry Month" 
                                placeholder="MM"
                                type="text" 
                                inputProps={{
                                  maxLength:2, 
                                  inputMode:'numeric'
                                }}
                                InputProps={{
                                  className:"small-input"
                                }}
                                value={this.state.cardMonth} 
                                onChange={(e) => this.setState({cardMonth:e.target.value,errors:Object.assign(errors, {cardMonth:null}) }) }
                                onKeyPress={(e) => this.numericKeyPress(e)}
                                helperText={ (!this.state.cardMonth) ? errors["cardMonth"] : ""}
                                error={ (errors["cardMonth"]) ? true : false }
                                />
                              <div style={{width: "6px"}}></div>
                              <TextField 
                                label="Expiry Year" 
                                placeholder="YY"
                                type="text" 
                                inputProps={{
                                  maxLength:2,
                                  inputMode:'numeric'
                                }}
                                InputProps={{
                                  className:"small-input"
                                }}
                                value={this.state.cardYear} 
                                onChange={(e) => this.setState({cardYear:e.target.value,errors:Object.assign(errors, {cardYear:null}) }) }
                                onKeyPress={(e) => this.numericKeyPress(e)}
                                helperText={ (!this.state.cardYear) ? errors["cardYear"] : ""}
                                error={ (errors["cardYear"]) ? true : false }
                                />
                              <div style={{width: "6px"}}></div>
                              <TextField 
                                label="CVC" 
                                type="text" 
                                inputProps={{
                                  maxLength:4,
                                  inputMode:'numeric'
                                }}
                                InputProps={{
                                  className:"small-input",
                                  endAdornment: <InputAdornment position="start"><FaRegCreditCard /></InputAdornment>
                                }}
                                value={this.state.cardCvc} 
                                onChange={(e) => this.setState({cardCvc:e.target.value,errors:Object.assign(errors, {cardCvc:null}) }) }
                                onKeyPress={(e) => this.numericKeyPress(e)}
                                helperText={ (!this.state.cardCvc) ? errors["cardCvc"] : ""}
                                error={ (errors["cardCvc"]) ? true : false }/>
                            </div>
                            <span className="f-s mt-1" style={{maxWidth:'500px'}}>
                              By providing your card information, you allow SURFERforecast to charge your card for future payments in accordance with our  <a href="/content/terms" target="_blank">Terms of Service</a> and <a href="/content/privacy" target="_blank">Privacy Policy</a>.
                            </span>
                            <TextField 
                                label="Enter Promo Code" 
                                placeholder="Promo code"
                                className="mt-2"
                                type="text" 
                                InputProps={{
                                  startAdornment: <InputAdornment position="start"><FaPercent /></InputAdornment>,
                                  endAdornment: (this.state.couponId) ? (<InputAdornment position="start"><FaCheckCircle color="#4CAF50" size="30px"/></InputAdornment>) : null
                                }}
                                onChange={(event) => this.promoChangeHandler.next(event)}
                                helperText={ (errors["couponId"]) ? errors["couponId"] : ""}
                                error={ (errors["couponId"]) ? true : false }
                              />
                          </div>
                          <div className="flex-row mt-1">
                            <FaLock color=" #BDBDBD"/>
                            <span className="ml-1 f-light">Payments are secured and encripted using Stripe</span>
                          </div>
                          <a href="https://stripe.com/" target="_blank"><StripeSvg width="150px" style={{marginTop:'6px'}} /></a>
                        </div>
                      </div>
                      
                      <Button 
                        variant="contained"
                        style={{backgroundColor:'#5cdb5c',height:'80px'}} 
                        onClick={this.resisterUser}>Subscribe</Button>

                      <span className="f-bold f-l mb-1 mt-1">Our Plans:</span>

                      <div className="subs-card-left-Align">
                        <p className="subs-right-text">Free</p>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#1976D2"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            Free 3 Day forecasts
                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#1976D2"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            Free 7 Day wind forecasts 
                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                    
                      
                      </div>
                      <div className="subs-card-left-Align">
                        <p className="subs-right-text">Premium</p>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#1976D2"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            30 Day Free Trial for 10 day forecasts
                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#1976D2"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            Monthly Membership unlocks access to all 10 day swell forecasts
                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#1976D2"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            Zoom into your local spot on the shoreline to see the nearshore forecast
                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#1976D2"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            The Oahu Northshore map below has the best swell resolution of all our maps
                            </p>
                          </div>
                          <div className="subs-borderline"></div>
                        </div>
                       
                      </div>



                      <div
                        style={{
                          marginBottom: "15px",
                        }}
                      >
                        <div className="f-bold mb-1">Pricing:</div>
                        <p className="subs-right-subtext">We require a credit card to sign up for a free trial, but we will will send you a reminder email to help you remember when your trial is over. If you don't like our forecast tools, you can cancel without penalty.</p>
                       <br />
                        <p className="subs-right-subtext">
                        We are a super small team trying to build forecast tools to help you surf more. We are a startup and sometimes there will be bugs in our tools, but we appreciate your feedback, as it helps us build more features for you.
                        </p>
                        <br />
                        <p className="subs-right-subtext">
                        SURFERforecast is subscriber-supported and relies on surfers/supports like you. Your support is greatly appreciated!
                        </p>
                      <br></br>
                        <div
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#1976D2"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            30 Day Free Trial
                            </p>
                          </div>
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#1976D2"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            1 Month Subscription: $2.99 US
                            </p>
                          </div>
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#1976D2"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            12 Month Subscription $29.99 US
                            </p>
                          </div>
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#1976D2"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            10 Day forecasts are only available to SURFER Maps Premium Members.
                            </p>
                          </div>
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#1976D2"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            Purchase a Monthly Premium Membership Subscription to unlock unlimited access to all 10-Day SURFER forecast maps. Monthly Access is available for $2.99/mo which provides access for 30 days.


                            </p>
                          </div>
                          <div className="radio-text-align">
                            <AiFillCheckCircle
                              size="20px"
                              color="#1976D2"
                              className="radio-icon"
                            />
                            <p className="subs-right-subText">
                            Monthly subscription automatically renews every 30 days. Annual subscription automatically renews every 12 months.


                            </p>
                          </div>
                          <div className="f-bold">Methodology:</div>
                          <p className="p-1">
                          The SURFER Maps technology utilizes a series of nested numerical grids in order to provide predictive data for the 7-day nearshore map forecasts. These forecasts refresh daily at 3-hourly intervals.
                          </p>
                          <p className="p-1">We’re using US NOAA wave models to forecast waves at the global, regional and higher resolution nearshore transformation models. Additionally, we’re using some deeper modeling developed by the US Army Corps of Engineers for visual presentation.</p>
                          <p className="p-1">These maps are reminiscent of the Swellwatch and Wetsand maps. (The colors represent the relative wave height OR period along the shoreline)</p>
                          <p className="p-1">
                            *Contact us if you run into any payment issues
                            <br/>
                            <a href="mailto:contact@surfable.co">(contact@surfable.co)</a>
                          </p>
                          <p className="p-1 f-s">
                            Also, here are our  <a href="/content/terms" target="_blank">Terms & Conditions</a> and <a href="/content/privacy" target="_blank">Privacy Policy</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mockup-wrap flex-column flex-align-center">
                    <img src={mockup} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.isLoading ? (
            <div className="sweet-loading">
              <ClipLoader size={50} color={"#ffffff"} loading={true} />
            </div>
          ) : null
        }
      </div >
    );
  }
}
