import React, { Component } from "react";
import Header from "../Header";
import 'leaflet.markercluster';
import "rc-slider/assets/index.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import left_arrow from "../../../assets/images/left-arrow.svg";
import down_svg from "../../../assets/images/down.svg";
import close from "../../../assets/images/close.png";
import Slider from "rc-slider";
import ClipLoader from "react-spinners/ClipLoader";
import AppService from "../../../commons/AppService";
import { AiOutlinePause, AiFillPlayCircle, AiFillFastForward, AiFillFastBackward } from "react-icons/ai";
import CircularProgress from '@mui/material/CircularProgress';
import { BsPlayFill } from "react-icons/bs";
import { VasatModel } from "vasat";
import { map, mergeMap, of } from "rxjs";
import moment from "moment-timezone";
import $ from "jquery";
import L from "mapbox.js";
import * as Hammer from "hammerjs"; 

toast.configure();

let regionId = "";
let thisRef = null;
let mapObj = null;
const mapToken = "pk.eyJ1IjoiZGl0dG9wcyIsImEiOiJjazhuNDJlMWkwaThuM25uc2JoYmxtcW8wIn0.2063I606c-KsbhBfO1KV_A";

export default class SimpleForecast extends Component {

  service = AppService.getInstance();
  dropdownLocationMenu = React.createRef();
  simpleForecastObj = null;
  animationTimeout = null;
  animationSpeed = 800;
  animationSpeedFast = 500;

  constructor(props) {
    super(props);
    thisRef = this;
    this.imageRef = refNode => {
      // same as Hooks example, re-render on changes
      console.log("ENTER imageRef node",refNode)
      this.setupImage(refNode)
    };
    this.state = {
      open: false,
      isLoading: false,
      isLoaded:false,
      isPlaying:false,
      isSubscribed: false,
      showSubscribe: false,
      preloadTotal:0,
      preloadCount:0,
      sliderMax: 1,
      frameCount: 10,
      region:{},
      regions:[],
      regionImageFileUrls: [],
      regionImageElements: [],
      currentImageUrl:null,
      simpleForecastObj:null,
      currentImageIdx:0
    };
  }

  handleButtonClick = () => {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };

  preload(imageArray) {

    let imageUrls = imageArray.map(obj => this.state.simpleForecastObj.fileUrl(obj.name, true, false) )

    //console.log("ENTER preload imageUrls ",imageUrls)
  
    imageUrls.forEach( imgUrl => {
      //console.log("ENTER preload imgObj ",imgObj);
      var img=new Image();
      img.addEventListener("load", () => this.resolve(img));
      img.addEventListener("error", err => this.reject(err));
      img.src = imgUrl;
    });

    this.setState({preloadTotal:imageArray.length,preloadCount:0,currentImageIdx:0,regionImageFileUrls:imageUrls});

    //
    
    //imageBounds = [center, [-35.8650, 154.2094]];

    if (mapObj) {
      console.log("ENTER map setting first image ", imageUrls[0]);
      L.imageOverlay(imageUrls[0]).addTo(mapObj);
    }
    //

    this.createSliderLegend();
  }

  resolve(img) {
    //console.log("ENTER resolve",img);
    this.setState({preloadCount: this.state.preloadCount+1});
  }
  reject(err) {
    this.setState({preloadCount: this.state.preloadCount+1});
  }

  initData(regId) {

    // Get list of regions
    this.service.getRegions().pipe(
      map( res => {
        // get selected region object
        var selectedRegion = res.find(ele => ele.id == regId);
        this.setState({
          regions: res,
          region: selectedRegion
        });
        return selectedRegion;
      }),
      mergeMap( reg => {
        // get simple forefcast object details from vasat
        var forecastObj = new VasatModel(this.service.vasat, "SimpleForecast",{id:reg.simpleForecast});
        return forecastObj.fetch();
      }),
      mergeMap(forecastObj => {
        this.setState({simpleForecastObj:forecastObj});
        // get images from vasat
        return forecastObj.files.query();
      }),
      map(imagesRes => {
        // preload the images
        if (imagesRes.items && imagesRes.items.length > 0) this.preload(imagesRes.items, null);
      }),
      mergeMap(res => {
        // get current user token
        let userToken = this.service.getCurrentAccessToken()
        //console.log("ENTER initData userToken ",userToken);
        if (userToken) {
          // if we have token fetch subscription
          //console.log("ENTER initData fetching subs ");
          return this.service.getSubscriptionObs();
        } else {
          // if no token then no subscription
          //console.log("ENTER initData no subs ");
          return of(null);
        }
      }),
      map(subscription => {
        this.setState({isSubscribed: subscription!=null });
      })
    ).subscribe()
    
  }

  setupImage(imgElement) {

    if (imgElement) {
      let initTransform = 'translate3d(0, 0, 0) scale3d(1.7, 1.7, 1)';
      imgElement.style.webkitTransform = initTransform;

      //console.log("ENTER setUpImagePinch imgElement",imgElement)
      var hammertime = Hammer(imgElement);
      hammertime.get('pinch').set({ enable: true });
      //console.log("ENTER setUpImagePinch hammertime",hammertime)
      
      var posX = 0,
        posY = 0,
        scale = 1,
        last_scale = 1,
        last_posX = 0,
        last_posY = 0,
        max_pos_x = 0,
        max_pos_y = 0,
        transform = "",
        el = imgElement;

      hammertime.on('doubletap pan pinch panend pinchend', ev => {
        if (ev.type == "doubletap") {
            transform =
                "translate3d(0, 0, 0) " +
                "scale3d(2, 2, 1) ";
            scale = 2;
            last_scale = 2;
            try {
                if (window.getComputedStyle(el, null).getPropertyValue('-webkit-transform').toString() != "matrix(1, 0, 0, 1, 0, 0)") {
                    transform =
                        "translate3d(0, 0, 0) " +
                        "scale3d(1, 1, 1) ";
                    scale = 1;
                    last_scale = 1;
                }
            } catch (err) {}
            el.style.webkitTransform = transform;
            transform = "";
        }

        //pan    
        if (scale != 1) {
            posX = last_posX + ev.deltaX;
            posY = last_posY + ev.deltaY;
            max_pos_x = Math.ceil((scale - 1) * el.clientWidth / 2);
            max_pos_y = Math.ceil((scale - 1) * el.clientHeight / 2);
            if (posX > max_pos_x) {
                posX = max_pos_x;
            }
            if (posX < -max_pos_x) {
                posX = -max_pos_x;
            }
            if (posY > max_pos_y) {
                posY = max_pos_y;
            }
            if (posY < -max_pos_y) {
                posY = -max_pos_y;
            }
        }


        //pinch
        if (ev.type == "pinch") {
            scale = Math.max(.999, Math.min(last_scale * (ev.scale), 4));
        }
        if(ev.type == "pinchend"){last_scale = scale;}

        //panend
        if(ev.type == "panend"){
            last_posX = posX < max_pos_x ? posX : max_pos_x;
            last_posY = posY < max_pos_y ? posY : max_pos_y;
        }

        if (scale != 1) {
            transform =
                "translate3d(" + posX + "px," + posY + "px, 0) " +
                "scale3d(" + scale + ", " + scale + ", 1)";
        }

        if (transform) {
            el.style.webkitTransform = transform;
        }
      });
    }
  }

  componentDidMount() {
    regionId = this.props.match.params.regionId;

    this.initData(regionId);
  }

  componentWillUnmount() {
    
  }

  renderLocation() {
    if (regionId == 'socal') {
      return <title>Southern California | SURFERforecast Maps</title>;


    } else if (regionId == 'nocal') {
      return <title>Northern California | SURFERforecast Maps</title>;


    }
    else if (regionId == 'oahu') {
      return <title>Oahu | SURFERforecast Maps</title>;


    }
    else if (regionId == 'hawaiian') {
      return <title>Hawaii| SURFERforecast Maps</title>;

    }
  }


  renderMeta() {
    if (regionId == 'socal') {

      return <meta name='description' content='7-Day Southern California Surf Wave Heights, Period and Swell Forecast'></meta>;



    } else if (regionId == 'nocal') {
      return <meta name='description' content='7-Day Northern California Surf Wave Heights, Period and Swell Forecast'></meta>;


    }
    else if (regionId == 'oahu') {
      return <meta name='description' content='7-Day Oahu Surf Wave Heights, Period and Swell Forecast'></meta>;


    }
    else if (regionId == 'hawaiian') {
      return <meta name='description' content='7-Day Hawaiian Island Surf Wave Heights, Period and Swell Forecast'></meta>;
    }
  }

  playControl(type) {
    if (!this.state.isPlaying || type != 'play') {
      // Start animation
      this.playAnimation(type);
      this.setState({isPlaying:true})
    } else {
      // pause/stop animation
      this.stopAnimation();
    }    
  }

  playAnimation(type) {
    if (this.animationTimeout != null) clearTimeout(this.animationTimeout)
    this.animationTimeout = setTimeout( () => {
      let notLastFrame = this.state.currentImageIdx < this.state.sliderMax;
      let newIdx = (type == 'fastRewind') ? this.state.currentImageIdx-1 : this.state.currentImageIdx+1;
      this.setState({currentImageIdx: notLastFrame ? newIdx : 0,showSubscribe:!notLastFrame })
      if (notLastFrame) {
        // Keep playing 
        this.playAnimation(type);
      } else {
        // Stop animation if it has displayed all images
        this.stopAnimation();
      }
      
    } , (type == "play") ? this.animationSpeed : this.animationSpeedFast);
  }

  stopAnimation() {
    clearTimeout(this.animationTimeout);
    this.animationTimeout = null;
    this.setState({isPlaying:false});
  }

  onSliderChange(event) {
    console.log("onSliderChange event",event);
    
    this.setState({currentImageIdx:event})

  }

  createSliderLegend() {
    let previous = "";
    $(".slider-legend-wrap").html("");
    $(".slider-legend-wrap-date-details").html("");
    $(".slider-legend-wrap-time").html("");
    // This method needs to be called after we have fetch the simpleForecastObj from the server
    //console.log("ENTER createSliderLegend simpleForecastObj",this.state.simpleForecastObj);
    let start = this.state.simpleForecastObj.lastTimeGroup;
    // Free version shows up to 3 days of forecast, so we keep track in order to limit the Timneline/Slider
    let daysCounter = 0;
    let sliderFrame = 1; 
    for (let count = 0; count < this.state.preloadTotal; count++) {
      let dat = moment(start,'YYYYMMDDHHmm').add(count * 3, "hours");
      //console.log("ENTER createSliderLegend add: "+(count * 3)+" date",dat.format("DD/MM hh:mm A"));
      if (previous != dat.format("DD/MM")) {
        // Break loop if not subscribe. Only allow 3 days for free version
        if (daysCounter == 3 && !this.state.isSubscribed) {break;}
        daysCounter = daysCounter + 1;
        $(".slider-legend-wrap").append(
          "<div class='legend-item date'><span class='slider-legend-date'>" +
          dat.format("DD") +
          "</span><span class='slider-legend-day'>" +
          dat.format("ddd") +
          "</span></div>"
        );

        $(".slider-legend-wrap-time").append(
          "<div class='legend-item date'><span class='slider-legend-day'>" +
          dat.format("ddd") +
          "</span></div>"
        );
        $(".slider-legend-wrap-date-details").append(
          "<div class='legend-item date'><span class='slider-legend-day'>" +
          dat.format("DD") +
          "</span></div>"
        );
      } else {
        $(".slider-legend-wrap").append(
          "<div class='legend-item time'>" + dat.format("DD/MM hh A") + "</div>"
        );
      }
      sliderFrame = sliderFrame + 1;
      previous = dat.format("DD/MM");
    }
    // Set slider max value
    this.setState({
      sliderMax: sliderFrame,
    });
    
  }

  hideSubscribePop() {
    this.setState({
      showSubscribe: false
    })
  }

  goToRegion = (region) => {
    //console.log("ENTER goToRegion",region);
    // Check if region is simpleForecast and redirect accordingly
    if (region.simpleForecast) {
      this.props.history.push(`/forecast/${region.id}`);
      window.location.reload();
    } else {
      this.props.history.push(`/swell_map/${region.id}`);
      window.location.reload();
    }
  };


  render() {

    return (

      <>
        <Helmet>
          {this.renderLocation()}
          {this.renderMeta()}
        </Helmet>
        <Header />
        <div className="outer">
          <div className="map-wrap">
            {/*<div id="map-canvas" ></div>*/}
            <div className="swell-map-container">
              <div className="wrapper location-box-align">
                <div style={{ justifyContent: "flex-start" }}>
                  <div className="location-box-wrap">
                    <div
                      className="left-arrow-wrap"
                      onClick={() => this.props.history.push("/")}

                    >
                      <img src={left_arrow} onClick={() => this.props.history.push("/")} width="20px" />
                    </div>
                    <div className="loc_round">
                      {this.state.region ? this.state.region.abbreviation : "NA"}
                    </div>
                    <p className="loc_name">
                      {this.state.region ? this.state.region.name : "NA"} 
                    </p>
                    <div className="swellMapdropdownMenu" ref={this.dropDownLocationMenu} >
                      <div className="down-box" onClick={this.handleButtonClick}>

                        <img src={down_svg} width="11px" />

                      </div>
                      {this.state.open && (
                        <div className="dropdown-items">
                          <div>
                            <ul>
                              {
                                this.state.regions.map(region => 
                                  <li
                                    key={region.name}
                                    className="items"
                                    onClick={() => this.goToRegion(region)}
                                  >
                                    {region.name}
                                  </li> )
                              }
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.currentSpot && <span className="spot-name">{this.state.currentSpot.spot}</span>}
                </div>
              </div>
 
              {(this.state.regionImageFileUrls && this.state.regionImageFileUrls.length > 0) ? (
                <div className="flex-row flex-center mt-3">
                  <img className="w-100" ref={this.imageRef} src={this.state.regionImageFileUrls[this.state.currentImageIdx]}></img>
                </div>
              ) : null
              }

              <div className="wrapper" >
                <div className="option-wrap">
                  
                </div>
                <div className="info-box-wrap">

                  <div className="mr-1 flex-column flex-center">
                    <div className="title-wrap mt-1">
                        <div
                          className="play-btn"
                          onClick={(e) => (this.state.preloadCount < this.state.preloadTotal) ? null : this.playControl('fastRewind')}
                        >

                          <AiFillFastBackward
                            className="play_btn pointer"
                            size="45px"
                            color={(this.state.preloadCount < this.state.preloadTotal) ? "#9E9E9E" : "#F76C6C"}
                          />

                        </div>
                        
                        <div
                          className="play-btn"
                          style={{position:'relative',height:'35px'}}
                          onClick={(e) => (this.state.preloadCount < this.state.preloadTotal) ? null : this.playControl('play')}>

                          { (this.state.preloadCount < this.state.preloadTotal) ? (<CircularProgress style={{position:'absolute',width:'30px',height:'30px'}}/>) : null }
                          
                          {
                            (!this.state.isPlaying) ? (
                              <BsPlayFill
                                className="play_btn pointer"
                                size="45px"
                                color={(this.state.preloadCount < this.state.preloadTotal) ? "#9E9E9E" : "#F76C6C"}
                                style={{position:'absolute'}}
                              />
                            ) : (
                              <AiOutlinePause
                                className="pointer"
                                size="40px"
                                color="#F76C6C"
                                style={{position:'absolute'}}
                              />
                            )
                          }

                        </div>
                        
                        <div
                          className="play-btn"
                          onClick={(e) => (this.state.preloadCount < this.state.preloadTotal) ? null : this.playControl('fastForward')}
                        >

                          <AiFillFastForward
                            className="play_btn pointer"
                            size="45px"
                            color={(this.state.preloadCount < this.state.preloadTotal) ? "#9E9E9E" : "#F76C6C"}
                          />

                        </div>
                      </div>
                    
                    { (this.state.preloadCount < this.state.preloadTotal) ? (<div className="f-xs text-center">{ ((this.state.preloadCount*100) / this.state.preloadTotal) | 0 }% loaded</div>) : null }
                  </div>

                  <div className="slider-container">

                    <div className="slider-wrap ui-slider" id="slider-wrap">
                      <div className="slider-legend-wrap"></div>

                      <div className="play-pause-mobile">
                        <div className="slider-legend-wrap-date-details"></div>
                      </div>

                      <div className="play-pause-web">

                        <Slider
                          min={0}
                          max={this.state.sliderMax - 1}
                          value={this.state.currentImageIdx}
                          onChange={(e) => this.onSliderChange(e)}

                          trackStyle={{
                            backgroundColor: "#FF654969",
                            height: "5.5px",
                            borderRadius: "0px",
                          }}
                          railStyle={{
                            backgroundColor: "#FF654969",
                            height: "5.5px",
                            borderRadius: "0px",
                          }}
                          step={1}
                          handleStyle={{
                            height: "16px",
                            width: "5.5px",
                            marginLeft: "0px",
                            marginTop: "-10.5px",
                            backgroundColor: "#F76C6C",
                            border: "none",
                            borderRadius: "1px",
                          }}
                          dotStyle={{
                            width: 30,
                            border: "none",
                            borderRadius: 0,
                            height: 1,
                            backgroundColor: "blue",
                          }}
                          activeDotStyle={{
                            backgroundColor: "red",
                          }}
                        // onBeforeChange={() => this.tl.pause()}
                        // onAfterChange={this.onAfterChange}
                        />

                      </div>



                      <div className="play-pause-mobile">
                        <Slider
                          min={0}
                          max={this.state.sliderMax - 1}
                          value={this.state.currentImageIdx}
                          onChange={(e) => this.onSliderChange(e)}
                          trackStyle={{
                            backgroundColor: "#FF654969",
                            height: "1px",
                            borderRadius: "0px",
                          }}
                          railStyle={{
                            backgroundColor: "#FF654969",
                            height: "1px",
                            borderRadius: "0px",
                          }}
                          step={1}
                          handleStyle={{
                            // height: "13px",
                            // width: "4px",
                            marginLeft: "0px",
                            marginTop: "-7px",
                            backgroundColor: "#F76C6C",
                            border: "none",
                            // borderRadius: "1px",
                          }}
                          dotStyle={{
                            width: 30,
                            border: "none",
                            borderRadius: 0,
                            height: 1,
                            backgroundColor: "blue",
                          }}
                          activeDotStyle={{
                            backgroundColor: "red",
                          }}
                        // onBeforeChange={() => this.tl.pause()}
                        // onAfterChange={this.onAfterChange}
                        />
                      </div>
                      <div className="play-pause-mobile">
                        <div className="slider-legend-wrap-time"></div>
                      </div>
                    </div>

                    {/* (!this.state.isSubscribed || this.state.token == null) ?
                      (
                        <div className="subcribe-box-align" >
                          <div className="subscribe-box-wrap">
                            <div>

                              <p className="subscribe-box-subtext">
                                Subscribe to view 10-day forecast
                            </p>
                            </div>
                            <button
                              className="subscribe_btn"
                              // onClick={() => this.goToSubscriptionPage()}
                              onClick={() => this.props.history.push(`/subscription`)}
                            >
                              Free Trial
                          </button>
                          </div>
                        </div>
                      ) : null */}

                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.isLoading ? (
            <div className="sweet-loading">
              <ClipLoader size={50} color={"#ffffff"} loading={true} />
            </div>
          ) : null}
          {this.state.showSubscribe ? <div className="popup-container" onClick={() => this.hideSubscribePop()}>
            <div className="popup-outer" onClick={(e) => e.stopPropagation()}>
              <img src={close} className="close-icon" onClick={() => this.hideSubscribePop()} />
              <h2>Subscribe Now!</h2>
              <p>10 day surf wave height & period maps for Southern California, Northern California and Hawaii</p>
              <button className="subscribe-btn" onClick={() => this.props.history.push(`/subscription`)}>Subscribe</button>
            </div>
          </div> : null}
        </div>
      </>
    );
  }
}
